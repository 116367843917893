import React from 'react'


function Day1() {
    
  return (
    <div className='width-full'>
        <div className="flex-col align-center ">
            <div className='top-card  text-align-center'>
                <h1 className='m-btm-zero'>Day 1</h1>
            </div>
            <div className='m-top-2 width-80'>
                <div>-1400H</div>
                <h4>Check-In</h4><br/>
                <div><b>Location:</b></div>
                <div>Hotel Location, 128941 Jaipur, India</div><br/>
                <div><b>Dress Code:</b></div>
                <div>None</div><br/>
                <div><b>Instructions:</b></div>
                <div>Just show up</div><br/>
            </div>
            <div className='m-top-2 width-80'>
                <div>1900H-2300H</div>
                <h4>Engagement</h4><br/>
                <div><b>Location:</b></div>
                <div>Hotel Location, 128941 Jaipur, India</div><br/>
                <div><b>Dress Code:</b></div>
                <div>Formal</div><br/>
                <div><b>Instructions:</b></div>
                <div>In publishing and graphic design, 
                    Lorem ipsum is a placeholder text commonly used to demonstrate the visual 
                    form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before final copy is available. WikipediaChoose what you’re giving feedback onOr give general feedback</div><br/>
            </div>
        </div>
    </div>
  );
}


export default Day1;
