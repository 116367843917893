import './App.css';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import Home from './Components/Home';
import RSVP from './Components/RSVP';
import Day1 from './Components/Day1';
import Photos from './Components/Photos';

function App() {
  return (
    <Router>
    <div className="App">
      <Switch>
        <Route exact path='/' ><Home/></Route>
        <Route path='/rsvp'><RSVP/></Route>
        <Route path='/day1'><Day1/></Route>
        <Route path='/photos'><Photos/></Route> 
      </Switch>
    </div>
    </Router>
  );
}

export default App;
