import React from 'react'
import {useState} from 'react'
import Main from '../Assets/Main.png'
import HomeIn from './HomeIn';

function Home() {
    const [isLogin, setLogin] = useState(false);
    console.log(isLogin)
    const handleClick = () => {
        setLogin(!isLogin);
        console.log(isLogin)
    }
    
  return (
    <div className='bg-fade width-full height-full'>
        <div className="flex-col align-center">
        {isLogin ? 
          <div>
            <HomeIn/>
          </div>
          :
          <div className="flex-col align-center">
              <img src={Main} id='main-img' alt='main-img'/>
              <div className='card text-align-center'>
              <h1>FluffyOreos</h1>
              <form className="flex-col">
                  <input placeholder='Email'/>
                  <input placeholder='Password'/>
                  <button className="black-button" onClick={handleClick}>Sign In</button>
                </form>
                  <a href='/rsvp'><button className='white-button'>RSVP</button></a>
              </div>  
          </div>
        }
        </div>
    </div>
  );
}


export default Home;
