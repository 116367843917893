import React from 'react'
import Profile from '../Assets/Photos.png'
import One from '../Assets/1.png'
import Two from '../Assets/2.png'
import Three from '../Assets/3.png'
import Four from '../Assets/4.png'
import Five from '../Assets/5.png'
import Six from '../Assets/6.png'
import Seven from '../Assets/7.png'
import Eight from '../Assets/8.png'
import Nine from '../Assets/9.png'
import Ten from '../Assets/10.png'



function Photos() {
    return (
        <div className='width-full'>
            <div className="flex-col align-center">
                <h5>Photos</h5>
                <div className='profile'>
                    <div>
                        <img src={Profile} id='profile-img' alt='Profile'/>
                    </div>  
                    <div className='text-align-center post-count'>
                        <b>10</b><br/>
                        posts
                    </div>
                </div>
                <div>
                    <div><b>FluffyOreos</b></div>
                    <div>
                        We are depending on you to upload photos.<br/>
                        They will be added to this gallery here.
                    </div>
                </div><br/>
                <button className='white-button width-80'>Upload Photos</button>
            </div>
            <div className='photo-section'>
                <img src={One} className='photo' alt='1'/>
                <img src={Two} className='photo' alt='2'/>
                <img src={Three} className='photo' alt='3'/>
                <img src={Four} className='photo' alt='4'/>
                <img src={Five} className='photo' alt='5'/>
                <img src={Six} className='photo' alt='6'/>
                <img src={Seven} className='photo' alt='7'/>
                <img src={Eight} className='photo' alt='8'/>
                <img src={Nine} className='photo' alt='9'/>
                <img src={Ten} className='photo' alt='10'/>
            </div>
        </div>
    )
}
export default Photos;