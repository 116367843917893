import React from 'react'
import Cam from '../Assets/Cam.png'
import Arrow from '../Assets/Arrow.png'

function HomeIn() {
    
  return (
    <div className='width-full'>
        <div className="flex-col align-center bg-fade text-align-center ">
            <div className='top-card'>
                <h1 className='m-btm-zero'>Hello</h1>
                <h2>Tia Kapoor</h2>
                <h6 className='m-btm-zero m-top-zero'>142 days left</h6>
                <a href='/photos'><img src={Cam} id='Camera' alt='Camera'/></a>
            </div>
            <div>
                <h2>Schedule</h2>
                <a href='day0'>
                    <div className='event-card'> 
                        <div className="flex-col text-align-left ">
                            <div className='small-text'>Day 0</div>
                            <div><b>Check-In + Engagement</b></div>
                        </div>
                        <img src={Arrow} className='arrow' alt='Arrow'/>
                    </div>
                </a>
                <a href='day1'>
                    <div className='event-card'> 
                        <div className="flex-col text-align-left ">
                            <div className='small-text'>Day 1</div>
                            <div><b>Mehendi</b></div>
                        </div>
                        <img src={Arrow} className='arrow' alt='Arrow'/>
                    </div>
                </a>
                <a href='day2'>
                    <div className='event-card'> 
                        <div className="flex-col text-align-left ">
                            <div className='small-text'>Day 2</div>
                            <div><b>Wedding + Chuda + Haldi</b></div>
                        </div>
                        <img src={Arrow} className='arrow' alt='Arrow'/>
                    </div>
                </a>
                <a href='day3'>
                    <div className='event-card'> 
                        <div className="flex-col text-align-left ">
                            <div className='small-text'>Day 3</div>
                            <div><b>Check out</b></div>
                        </div>
                        <img src={Arrow} className='arrow' alt='Arrow'/>
                    </div>
                </a>
            </div>
            <div>
                <h2>FAQ</h2>
                <a href='/accommodation'>
                    <div className='event-card'> 
                        <div><b>Accommodation</b></div>
                        <img src={Arrow} className='arrow' alt='Arrow'/>
                    </div>
                </a>
                <a href='/packinglist'>
                    <div className='event-card'> 
                        <div><b>Packing List</b></div>
                        <img src={Arrow} className='arrow' alt='Arrow'/>
                    </div>
                </a>
                <a href='/emergency'>
                    <div className='event-card'> 
                        <div><b>Emergency Contact</b></div>
                        <img src={Arrow} className='arrow' alt='Arrow'/>
                    </div>
                </a>
            </div>
        </div>
    </div>
  );
}


export default HomeIn;
