import React from 'react'
import Other from '../Assets/Other.png'

const RSVP = () => {
    
  return (
    <div>
        <div className="flex-col align-center">
            <img src={Other} id='other-img' alt='other-img'/>
            <div className='card text-align-center'>
            <h1>RSVP</h1>
            <h6>
                20 | 02 | 2022 <br/>
                to<br/>
                22 | 02 | 2022<br/><br/>
                Hotel Location, Jaipur, India<br/>
            </h6>
            <form className="flex-col">
                <input placeholder='Name'/>
                <input placeholder='Email'/>
                <input placeholder='Invite Code'/>
                <button className="black-button">Sign Up</button>
                <br/>
            </form>
                <a href='/'><button className='white-button'>Back</button></a>
            </div>  
        </div>
    </div>
  );
}


export default RSVP;
